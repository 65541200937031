import React, { useContext, useEffect } from 'react';
import { Link as RouterLink, useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Button,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
} from '@mui/material';
import {
  BookOpen as BookOpenIcon,
  Code as CodeIcon,
  Activity as ActivityIcon,
  FileText as FileTextIcon,
  User as UserIcon,
  Droplet as DropletIcon,
  Slack,
  Framer,
} from 'react-feather';
import NavItem from './NavItem';
import { PassportAuth } from '../../contexts/AuthContext';
import { getStorage } from '../../utils/functions/localstorage';
// import accessRight from '../../utils/functions/accessRight';

const items = [
  {
    href: '/monitor/dashboard',
    icon: ActivityIcon,
    title: 'แดชบอร์ด',
    name: 'MONITOR',
    sub: [],
  },
  {
    href: '/control/drive',
    icon: Framer,
    title: 'ระบบรถปลูกผัก',
    name: 'MANAGEMENT',
    sub: [
      {
        href: '/control/drive',
        title: 'ระบบขับเคลื่อน',
      },
      {
        href: '/control/cnc',
        title: 'CNC system',
      },
      {
        href: '/control/transport',
        title: 'Transport Lifting system',
      },
      {
        href: '/control/spray-bar',
        title: 'ระบบควบคุมปั๊มน้ำ',
      },
      {
        href: '/control/vegetable',
        title: 'Vegetable Plot',
      },
    ],
  },
  {
    href: '/BeanSprout/valve',
    icon: Slack,
    title: 'ระบบปลูกถั่วงอก',
    name: 'MANAGEMENT',
    sub: [
      {
        href: '/BeanSprout/valve',
        title: 'ระบบควบคุม',
      },
    ],
  },

  {
    href: '/profile/profile',
    icon: UserIcon,
    title: 'โปรไฟล์',
    name: 'PROFILE',
    sub: [],
  },
  {
    href: '/management/employee',
    icon: FileTextIcon,
    title: 'จัดการระบบ',
    name: 'MANAGEMENT',
    sub: [
      {
        href: '/management/employee',
        title: 'จัดการพนักงาน',
      },
      {
        href: '/management/role-type',
        title: 'จัดการบทบาท',
      },
      {
        href: '/management/user',
        title: 'จัดการผู้ใช้งาน',
      },
    ],
  },
];

const DashboardSidebar = ({ me, onMobileClose, openMobile }) => {
  const {
    firstname = '',
    lastname = '',
    department: { name: departmentName = '' } = {},
  } = JSON.parse(getStorage('remember'));

  const user = {
    jobTitle: departmentName,
    name: `${firstname} ${lastname}`,
  };

  const { handleSignout } = useContext(PassportAuth);
  const history = useHistory();

  const onSignOut = async () => {
    await handleSignout();
    history.push('/login');
  };

  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => {
            // if (accessRight(me, item.name)) {
            return (
              <div key={item.title}>
                <NavItem
                  href={item.href}
                  title={item.title}
                  icon={item.icon}
                  sub={item.sub}
                  me={me}
                  moduleName={item.name}
                />
              </div>
            );
            // }
            // return <></>;
          })}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Divider />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2,
        }}
      >
        <Button
          size="xs"
          startIcon={<i className="fas fa-sign-out-alt"></i>}
          variant="outlined"
          onClick={() => {
            onSignOut();
          }}
        >
          ออกจากระบบ
        </Button>
      </Box>
    </Box>
  );

  return (
    <div>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256,
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)',
              zIndex: 0,
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </div>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  me: PropTypes.object,
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default DashboardSidebar;
