import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';
import { Card, Chip } from '@mui/material';
import { Shower } from '@mui/icons-material';

export default function ValveControl({ title, subtitle }) {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const [valveStatus, setValveStatus] = useState('On');
  const [sprinklerStatus, setSprinklerStatus] = useState('On');

  const [valve1Status, setValve1Status] = useState('Off');
  const [valve2Status, setValve2Status] = useState('Off');
  const [valve3Status, setValve3Status] = useState('Off');
  const [valve4Status, setValve4Status] = useState('Off');
  const [valve5Status, setValve5Status] = useState('Off');
  const [valve6Status, setValve6Status] = useState('Off');

  const handleValveToggle = (valveStatus) => {
    const newStatus = valveStatus === 'On' ? 'Off' : 'On';
    if (valve1Status === 'On') {
      const data = {
        topic: 'vlave all',
        payload: 'on',
      };
      dispatch(actions.forwardControl(data));
    } else {
      const data = {
        topic: 'vlave all',
        payload: 'off',
      };
      dispatch(actions.forwardControl(data));
    }
    setValveStatus(newStatus);
    setValve2Status(newStatus);
    setValve3Status(newStatus);
    setValve5Status(newStatus);
    setValve6Status(newStatus);
  };
  const handleValve2Toggle = () => {
    const newStatus = valve2Status === 'On' ? 'Off' : 'On';
    if (newStatus === 'On') {
      const data = {
        topic: 'v2',
        payload: 'on',
      };
      dispatch(actions.forwardControl(data));
    } else {
      const data = {
        topic: 'v2',
        payload: 'off',
      };
      dispatch(actions.forwardControl(data));
    }

    setValve2Status(newStatus);
  };

  const handleValve3Toggle = () => {
    const newStatus = valve3Status === 'On' ? 'Off' : 'On';
    if (newStatus === 'On') {
      const data = {
        topic: 'v3',
        payload: 'on',
      };
      dispatch(actions.forwardControl(data));
    } else {
      const data = {
        topic: 'v3',
        payload: 'off',
      };
      dispatch(actions.forwardControl(data));
    }
    setValve3Status(newStatus);
  };

  const handleValve5Toggle = () => {
    const newStatus = valve5Status === 'On' ? 'Off' : 'On';
    if (newStatus === 'On') {
      const data = {
        topic: 'v5',
        payload: 'on',
      };
      dispatch(actions.forwardControl(data));
    } else {
      const data = {
        topic: 'v5',
        payload: 'off',
      };
      dispatch(actions.forwardControl(data));
    }
    setValve5Status(newStatus);
  };

  const handleValve6Toggle = () => {
    const newStatus = valve6Status === 'On' ? 'Off' : 'On';
    if (newStatus === 'On') {
      const data = {
        topic: 'v6',
        payload: 'on',
      };
      dispatch(actions.forwardControl(data));
    } else {
      const data = {
        topic: 'v6',
        payload: 'off',
      };
      dispatch(actions.forwardControl(data));
    }
    setValve6Status(newStatus);
  };

  const handleSprinklerToggle = () => {
    const newStatus = sprinklerStatus === 'On' ? 'Off' : 'On';
    if (newStatus === 'On') {
      const data = {
        topic: 'pump',
        payload: 'on',
      };
      dispatch(actions.forwardControl(data));
    } else {
      const data = {
        topic: 'pump',
        payload: 'off',
      };
      dispatch(actions.forwardControl(data));
    }
    setSprinklerStatus(newStatus);
  };

  const handleValveDrainToggle = () => {
    const newStatus = valve4Status === 'On' ? 'Off' : 'On';
    if (newStatus === 'On') {
      const data = {
        topic: 'v4',
        payload: 'on',
      };
      dispatch(actions.forwardControl(data));
    } else {
      const data = {
        topic: 'v4',
        payload: JSON.stringify({ command: 'off', valve: 'v4' }),
      };
      dispatch(actions.forwardControl(data));
    }
    setValve4Status(newStatus);
  };

  const handleValvePumpToggle = () => {
    const newStatus = valve1Status === 'On' ? 'Off' : 'On';
    if (newStatus === 'On') {
      const data = {
        topic: 'v1',
        payload: 'on',
      };
      dispatch(actions.forwardControl(data));
    } else {
      const data = {
        topic: 'v1',
        payload: 'off',
      };
      dispatch(actions.forwardControl(data));
    }
    setValve1Status(newStatus);
  };
  return (
    <div>
      {renderTitle()}
      <div className="bg-white-100 p-4 rounded shadow-md">
        <h2 className="text-lg font-bold text-black-700 mb-2">
          {' '}
          การควบคุมวาล์วทั้งหมด
        </h2>
        <button
          className={`text-white font-bold py-2 px-4 rounded ${
            valveStatus === 'On' ? 'bg-red-500' : 'bg-green-500'
          }`}
          onClick={() => handleValveToggle(valveStatus, setValveStatus)}
        >
          {valveStatus === 'On' ? 'TURN OFF ' : 'TURN ON '}
        </button>
        <p className="mt-2 text-blue-600">Valve is {valveStatus}</p>
        <div>
          <h2 className="text-lg font-bold text-black-700 mb-2">
            การควบคุมปั้มน้ำ
          </h2>
          <button
            className={`text-white font-bold py-2 px-4 rounded ${
              sprinklerStatus === 'On' ? 'bg-red-500' : 'bg-green-500'
            }`}
            onClick={handleSprinklerToggle}
          >
            {sprinklerStatus === 'On' ? 'TURN OFF ' : 'TURN ON '}
          </button>
          <p className="mt-2 text-blue-600">Pump are {sprinklerStatus}</p>
        </div>

        <h2 className="font-bold text-lg">การควบคุมการทำงานของวาล์ว</h2>

        <div className="py-2 grid lg:grid-cols-4 grid-cols-2 gap-4">
          <div onClick={handleValve2Toggle} className="py-1">
            <Card>
              <div
                className={`${
                  valve2Status === 'On' ? 'bg-green-500' : 'bg-red-500'
                } text-white font-bold p-8 w-30 h-40 flex justify-center items-center`}
              >
                {valve2Status === 'On' ? (
                  <div>
                    <div className="text-center">
                      <Shower sx={{ fontSize: 60 }} />
                    </div>
                    TURN OFF VALVE 2
                  </div>
                ) : (
                  <div>TURN ON VALVE 2</div>
                )}
              </div>
            </Card>
          </div>
          <div onClick={handleValve3Toggle} className="py-1">
            <Card>
              <div
                className={`${
                  valve3Status === 'On' ? 'bg-green-500' : 'bg-red-500'
                } text-white font-bold p-8 w-30 h-40 flex justify-center items-center`}
              >
                {valve3Status === 'On' ? (
                  <div>
                    <div className="text-center">
                      <Shower sx={{ fontSize: 60 }} />
                    </div>
                    TURN OFF VALVE 3
                  </div>
                ) : (
                  <div>TURN ON VALVE 3</div>
                )}
              </div>
            </Card>
          </div>

          <div onClick={handleValve5Toggle} className="py-1">
            <Card>
              <div
                className={`${
                  valve5Status === 'On' ? 'bg-green-500' : 'bg-red-500'
                } text-white font-bold p-8 w-30 h-40 flex justify-center items-center`}
              >
                {valve5Status === 'On' ? (
                  <div>
                    <div className="text-center">
                      <Shower sx={{ fontSize: 60 }} />
                    </div>
                    TURN OFF VALVE 5
                  </div>
                ) : (
                  <div>TURN ON VALVE 5</div>
                )}
              </div>
            </Card>
          </div>
          <div onClick={handleValve6Toggle} className="py-1">
            <Card>
              <div
                className={`${
                  valve6Status === 'On' ? 'bg-green-500' : 'bg-red-500'
                } text-white font-bold p-8 w-30 h-40 flex justify-center items-center`}
              >
                {valve6Status === 'On' ? (
                  <div>
                    <div className="text-center">
                      <Shower sx={{ fontSize: 60 }} />
                    </div>
                    TURN OFF VALVE 6
                  </div>
                ) : (
                  <div>TURN ON VALVE 6</div>
                )}
              </div>
            </Card>
          </div>
        </div>

        <h2 className="font-bold text-lg">การควบคุมการปล่อยน้ำและสูบน้ำ</h2>

        <div className="py-2 grid grid-cols-2 gap-4">
          <div onClick={handleValvePumpToggle}>
            <Card>
              <div
                className={`${
                  valve1Status === 'On' ? 'bg-green-500' : 'bg-red-500'
                } text-white font-bold p-8 w-30 h-40 flex justify-center items-center`}
              >
                {valve1Status === 'On' ? (
                  <div>
                    <div className="text-center">
                      <Shower sx={{ fontSize: 60 }} />
                    </div>
                    TURN OFF VALVE PUMP
                  </div>
                ) : (
                  <div>TURN ON VALVE PUMP</div>
                )}
              </div>
            </Card>
          </div>

          <div onClick={handleValveDrainToggle}>
            <Card>
              <div
                className={`${
                  valve4Status === 'On' ? 'bg-green-500' : 'bg-red-500'
                } text-white font-bold p-8 w-30 h-40 flex justify-center items-center`}
              >
                {valve4Status === 'On' ? (
                  <div>
                    <div className="text-center">
                      <Shower sx={{ fontSize: 60 }} />
                    </div>
                    TURN OFF VALVE DRAIN
                  </div>
                ) : (
                  <div>TURN ON VALVE DRAIN</div>
                )}
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
